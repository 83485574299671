.navbar-brand {
    img {
        height: 60px;
    }
}
header nav .media {
    &:hover{
        width: 100%;
        margin-left: 2px;
    }
}